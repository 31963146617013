// See: https://nivo.rocks/guides/theming/

import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const fontFamily = "KHTeka, Helvetica Neue";

const SesamyNivoTheme = {
  labels: {
    text: {
      fontSize: 16,
      fill: twColors.white,
      fontFamily,
    },
  },
  legends: {
    itemSpacing: 4,
    text: {
      fontSize: 16,
      fontFamily,
      fill: twColors.white,
    },
  },
  tooltip: {
    fontFamily,
    fontSize: "14px",
    container: {
      background: twColors.gray[700],
      borderRadius: "6px",
      boxShadow:
        "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    },
    chip: {
      borderRadius: "100%",
      width: "8px",
      height: "8px",
    },
    tableCell: {
      color: twColors.gray[300],
    },
    tableCellValue: {
      color: twColors.white,
      fontWeight: 500,
    },
  },
  // Used in Line- & Bar chart
  axis: {
    ticks: {
      line: {
        stroke: "transparent",
      },
      text: {
        fill: twColors.gray[300],
      },
    },
    legend: {
      text: {
        fill: twColors.gray[300],
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 1,
      stroke: twColors.gray[600],
    },
  },
  crosshair: {
    line: {
      stroke: twColors.white,
    },
  },
};

export default SesamyNivoTheme;
