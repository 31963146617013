import dynamic from "next/dynamic";
import world_countries from "@components/Nivo/world_countries";
import { GeographicalMapResponse } from "@utils/graphHelpers";
import { max, min } from "lodash";
import { ComponentType } from "react";
import { ChoroplethCommonProps } from "@nivo/geo";

const ResponsiveChoropleth = dynamic(
  () =>
    import("@nivo/geo").then(
      ({ ResponsiveChoropleth }) =>
        ResponsiveChoropleth as ComponentType<
          ChoroplethCommonProps & { layers: any }
        >,
    ),
  { ssr: false },
);

const PURPLE_SCALE = [
  "#252048",
  "#433881",
  "#7D68F4",
  "#8C78FB",
  "#9B8BF9",
  "#B1A5F9",
  "#C9C1F9",
];

export default ({
  data,
  fullyRendered = () => {},
}: {
  data: GeographicalMapResponse["data"];
  fullyRendered?: Function;
}) => {
  const lowerBoundOfColorShading = min(data.map(({ value }) => value)) || 0;
  const upperBoundOfColorShading =
    max(data.map(({ value }) => value)) || 1000000;

  return (
    <div className="aspect-[2/1] w-full row">
      <ResponsiveChoropleth
        layers={["graticule", "features", () => fullyRendered(true)]}
        data={data}
        value="value"
        features={world_countries}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors={PURPLE_SCALE}
        domain={[lowerBoundOfColorShading, upperBoundOfColorShading]}
        unknownColor="#282834"
        label="properties.name"
        valueFormat=".2s"
        projectionScale={210}
        projectionTranslation={[0.5, 0.7]}
        borderWidth={0.25}
        borderColor="#14141A"
        tooltip={({ feature: { label, value } }) => {
          if (!label || !value) return null;
          return (
            <div className="rounded-2xl bg-gray-700 p-3 text-xs">
              <span>
                <strong>{label}: </strong>
              </span>
              <span>{value.toLocaleString()}</span>
            </div>
          );
        }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            translateX: 50,
            translateY: -50,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 22,
            itemDirection: "left-to-right",
            itemTextColor: "#999",
            symbolSize: 20,
          },
        ]}
      />
    </div>
  );
};
