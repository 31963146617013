import dynamic from "next/dynamic";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import Tooltip from "@components/Nivo/Tooltip";
import { numberFormat, shortDateFormat } from "@utils/numberFormat";
import Row from "@components/Ui/Row";
import Column from "@components/Ui/Column";
import { DynamicObject } from "@type-defs/common";
import { chunkArrayOfObjects } from "@utils/array-manipulation";
import { useMemo, useState } from "react";
import { ceilToNearest } from "@utils/calculations";
import { isValidDate } from "@utils/dates";
import { mergeObjects } from "@utils/object-manipulation";
import { timeFormat } from "d3-time-format";
import { scaleTime } from "d3-scale";
import { StatsApiGraphConfig } from "@type-defs/graph-api";

const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const ResponsiveBar = dynamic(
  () => import("@nivo/bar").then(({ ResponsiveBar }) => ResponsiveBar),
  { ssr: false },
);

import { ReactNode } from "react";

const GraphWrapper = ({
  dataLength,
  children,
}: {
  dataLength: number;
  children: ReactNode;
}) => {
  if (dataLength < 15) {
    return (
      <div className="-ml-[30px] aspect-[2.5/1] max-h-[300px] w-[calc(100%+30px)]">
        {children}
      </div>
    );
  }
  const width = dataLength * 70;
  // tailwind cant handle dynamic width so we need to use inline styles
  return (
    <div className="relative">
      <div
        className={
          "custom-scrollbar scrollbar-gray-800 z-10 overflow-x-auto p-3 pr-[70px]"
        }
      >
        <div
          className="h-[400px] h-full min-w-[calc(100%+30px)]"
          style={{ width: `${width}px` }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default ({
  data,
  indexBy = "name",
  isCurrency = false,
  startDate,
  endDate,
  graphConfig,
  fullyRendered = () => {},
}: {
  data: DynamicObject[];
  indexBy?: string;
  isCurrency?: boolean;
  startDate?: Date;
  endDate?: Date;
  graphConfig?: StatsApiGraphConfig;
  fullyRendered?: Function;
}) => {
  const disabledLegendsState = useState<string[]>([]);
  const [disabledLegends] = disabledLegendsState;

  const keys = Object.keys(mergeObjects(...data)).filter(
    (key) => !key.endsWith("Color") && ![indexBy, "range"].includes(key),
  );
  const indexIsDateOrNumber =
    isValidDate(data[0]?.[indexBy]) || !isNaN(data[0]?.[indexBy]);
  const colors = Object.values(twColors.gradient) as string[];
  const shouldChunk = indexIsDateOrNumber && data.length > 32; // we don't wanna group if not date or number
  const truncateTikAt = indexIsDateOrNumber
    ? 100 // we don't want to truncate dates or numbers
    : Math.max(7, Math.floor(150 / (data.length + 1)));
  const chunkedData = shouldChunk
    ? chunkArrayOfObjects(data, indexBy, 10)
    : data;
  const coloredData = chunkedData.map((node) =>
    Object.entries(node).reduce(
      (acc, [key, value]) =>
        key.endsWith("Color") || [indexBy, "range"].includes(key)
          ? {
              ...acc,
              [key]: value,
            }
          : {
              ...acc,
              [key]: value,
              [`${key}Color`]: colors[keys.indexOf(key) % colors.length],
            },
      {},
    ),
  );

  const filteredData = coloredData.map((node) =>
    Object.entries(node).reduce(
      (a, [key, value]) =>
        disabledLegends.includes(key)
          ? a
          : {
              ...a,
              [key]: value,
            },
      {},
    ),
  ) as DynamicObject[];

  const max = ceilToNearest(
    filteredData.reduce((acc, childData) => {
      const filtered = (Object.entries(childData) as [string, any][]).filter(
        ([key]) => !key.endsWith("Color") && ![indexBy, "range"].includes(key),
      );

      return Math.max(
        acc,
        filtered.reduce((a, [, value]) => (value < 0 ? a : a + value), 0),
      );
    }, 0),
  );

  const min = ceilToNearest(
    Math.abs(
      filteredData.reduce((acc, childData) => {
        const filtered = (Object.entries(childData) as [string, any][]).filter(
          ([key]) =>
            !key.endsWith("Color") && ![indexBy, "range"].includes(key),
        );

        return Math.min(
          acc,
          filtered.reduce((a, [, value]) => (value > 0 ? a : a + value), 0),
        );
      }, max),
    ),
  );

  // This is a custom implementation of the time scale ticks until Nivo Bar chart has native support for it
  // See: https://github.com/plouc/nivo/issues/1395
  const formatter = timeFormat("%Y-%m-%d");

  const timeScaleTicks: string[] = useMemo(() => {
    if (!startDate || !endDate)
      return data.map(({ [indexBy]: index }) => index);
    const scale = scaleTime().domain([startDate, endDate]);
    const ticks = scale.ticks(5);
    return ticks.map((tick) => formatter(tick));
  }, [data, endDate, formatter, indexBy, startDate]);

  const stacked =
    graphConfig?.stacked !== undefined ? !!graphConfig.stacked : true;
  return (
    <>
      {/* If it is a date or a number we don't want the wrapper do overflow, therefore we pass dataLength as 0*/}
      <GraphWrapper dataLength={indexIsDateOrNumber ? 0 : data.length}>
        <ResponsiveBar
          layers={[
            "grid",
            "axes",
            "bars",
            // "totals",
            "markers",
            "legends",
            "annotations",
            () => fullyRendered(),
          ]}
          data={filteredData}
          theme={SesamyNivoTheme}
          colors={colors}
          keys={keys}
          indexBy={indexBy}
          margin={{ top: 10, right: 40, bottom: 30, left: 30 }}
          axisLeft={null}
          axisRight={{
            tickValues: [
              ...(min
                ? [-min, -(min * 0.75), -(min * 0.5), -(min * 0.25)]
                : []),
              0,
              ...(max ? [max * 0.75, max * 0.5, max * 0.25, max] : []),
            ],
            format: (value) => {
              const isNumber = Math.floor(value) === value;

              if (isNumber) {
                const number = value / (isCurrency ? 100 : 1);
                return number >= 1000000
                  ? `${number / 1000000}M`
                  : number >= 1000
                    ? `${number / 1000}K`
                    : number;
              }
              return "";
            },
          }}
          axisBottom={{
            format: (value) => {
              if (!isValidDate(value)) return value;
              if (shouldChunk) return shortDateFormat(value, false);
              return timeScaleTicks.includes(value)
                ? shortDateFormat(value, false)
                : "";
            },
            truncateTickAt: truncateTikAt,
          }}
          padding={0.2}
          minValue={-min}
          maxValue={max}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          groupMode={stacked ? "stacked" : "grouped"}
          gridYValues={4}
          enableLabel={false}
          tooltip={({ data: { [indexBy]: title, range, ...props } }) => {
            return (
              <Tooltip className="invisible animate-appear">
                <Column className="gap-2" left>
                  <span className="font-medium">
                    {range
                      ? range
                      : indexBy === "date" && !title.toString().includes("~")
                        ? shortDateFormat(title as string)
                        : title}
                  </span>
                  <Column className="w-full !flex-col-reverse gap-1" left>
                    {Object.entries(props)
                      .filter(([key]) => !key.endsWith("Color"))
                      .map(([key, value], i) => (
                        <Row
                          className="w-full !justify-between gap-10"
                          key={key}
                          left
                        >
                          <Row className="gap-2" left>
                            <div
                              className="h-2 w-2 -translate-y-px rounded-full"
                              style={{
                                backgroundColor: props[`${key}Color`]
                                  ? (props[`${key}Color`] as string)
                                  : colors[i % colors.length],
                              }}
                            />
                            <span className="capitalize">{key}</span>
                          </Row>
                          {numberFormat(
                            parseFloat(value as string) /
                              (isCurrency ? 100 : 1),
                            false,
                            isCurrency ? 0 : 2,
                          )}
                        </Row>
                      ))}
                    {!graphConfig?.hideTotal && keys.length > 1 && (
                      <>
                        <hr className="my-2 h-[1px] w-full border-none bg-gradient-to-r from-gray-400  to-transparent" />
                        <Row
                          className="w-full !justify-between gap-10 font-medium"
                          left
                        >
                          <Row className="gap-2" left>
                            <span className="capitalize">Total</span>
                          </Row>
                          {numberFormat(
                            (
                              Object.entries(props).filter(
                                ([key]) => !key.endsWith("Color"),
                              ) as [string, number][]
                            ).reduce((acc, [, value]) => acc + value, 0) /
                              (isCurrency ? 100 : 1),
                            false,
                            isCurrency ? 0 : 2,
                          )}
                        </Row>
                      </>
                    )}
                  </Column>
                </Column>
              </Tooltip>
            );
          }}
        />
      </GraphWrapper>
    </>
  );
};
