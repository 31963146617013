import BigNumber from "@components/BigNumber";
import InfoTooltip from "@components/InfoTooltip/InfoTooltip";
import Row from "@components/Ui/Row";
import { twMerge } from "tailwind-merge";
import Column from "@components/Ui/Column";
import Icon from "@components/Ui/Icon";
import { IconName } from "icons/types";

type Props = {
  title: string;
  description?: string;
  value?: number;
  unit?: string;
  noSpacing?: boolean;
  gradient?: boolean;
  analyticsData?: {
    number: number;
    unit: string;
    description: string;
    icon?: string;
    size?: "lg";
    color?: "default";
  };
};

export default ({
  title,
  description,
  value,
  unit,
  noSpacing = false,
  analyticsData,
  gradient = false,
}: Props) => {
  return (
    <div
      className={twMerge(
        "flex h-full flex-1 flex-col",
        noSpacing && "justify-between",
        !noSpacing && "mb-6",
      )}
    >
      <Row className="relative mb-3 flex items-center" left up>
        <div
          className={twMerge(
            "text-base font-medium text-gray-300",
            gradient && "text-white",
          )}
        >
          {title}
        </div>
        {description && <InfoTooltip description={description} />}
        {analyticsData && analyticsData?.size !== "lg" && (
          <Column right className="ml-auto mr-0 text-base">
            <Row
              className={twMerge(
                "gap-1 font-medium text-secondary",
                analyticsData?.color !== "default" &&
                  analyticsData.number > 0 &&
                  "text-green",
                analyticsData?.color !== "default" &&
                  analyticsData.number < 0 &&
                  "text-red",
              )}
            >
              {analyticsData?.icon && (
                <Icon
                  name={analyticsData.icon as IconName}
                  className="text-xs sm:text-xs"
                />
              )}
              {analyticsData.number} {analyticsData.unit}
            </Row>
            <div className="text-sm md:text-sm">
              {analyticsData.description}
            </div>
          </Column>
        )}
      </Row>
      <Row
        className={twMerge(
          "text-[length:var(--headline-font-size,theme(spacing.10))] leading-[80%]",
          gradient && "text-4xl",
        )}
        left
      >
        {typeof value === "number" && (
          <BigNumber value={value} unit={unit} gradient={gradient} />
        )}
        {analyticsData && analyticsData?.size === "lg" && (
          <Column
            right
            className="ml-auto mr-0 w-auto shrink text-3xl lg:text-4xl"
          >
            <Row
              className={twMerge(
                "gap-1 font-medium text-secondary",
                analyticsData?.color !== "default" &&
                  analyticsData.number > 0 &&
                  "text-green",
                analyticsData?.color !== "default" &&
                  analyticsData.number < 0 &&
                  "text-red",
              )}
            >
              {analyticsData?.icon && (
                <Icon
                  name={analyticsData.icon as IconName}
                  className="lg:text-lg"
                />
              )}
              {analyticsData.number} {analyticsData.unit}
            </Row>
            <div className="text-sm tracking-wide md:text-sm">
              {analyticsData.description}
            </div>
          </Column>
        )}
      </Row>
    </div>
  );
};
