import { StatsApiResponseTypes, StatsResponse } from "@utils/graphHelpers";
import Pie from "@components/Nivo/Pie";
import Heatmap from "@components/Nivo/Heatmap";
import Line from "@components/Nivo/Line";
import RadialBar from "@components/Nivo/RadialBar";
import Container from "@components/Ui/Container";
import GraphHeader from "@components/GraphHeader";
import { twMerge } from "tailwind-merge";
import Bar from "@components/Nivo/Bar";
import HorizontalBar from "@components/Nivo/HorizontalBar";
import Choropleth from "@components/Nivo/Choropleth";
import ScatterPlot from "@components/Nivo/ScatterPlot";
import Row from "@components/Ui/Row";
import { useRouter } from "next/router";
import { useVendorContext } from "@contexts/vendor-context";
import Link from "next/link";
import Icon from "@components/Ui/Icon";
import { useEffect } from "react";

export default ({
  statsResponse = {},
  className = "",
  singleDate = false,
  startDate,
  endDate,
  isLegacyGraph = false,
  gradient = false,
  renderedState = [true, () => {}],
}: {
  statsResponse?: StatsResponse | any;
  className?: string;
  singleDate?: boolean;
  startDate?: Date;
  endDate?: Date;
  isLegacyGraph?: boolean;
  gradient?: boolean;
  renderedState?: [boolean, Function];
}) => {
  const [rendered, setRendered] = renderedState;
  const { selectedVendor } = useVendorContext();
  const { push } = useRouter();
  const {
    data,
    title,
    description,
    type,
    indexBy,
    cardValue,
    cardValueUnit,
    cardValueType,
    analyticsData,
    graphConfig,
    queryUrl,
  } = statsResponse;

  const numberGraph = [StatsApiResponseTypes.NUMBER].includes(type);
  const isCurrency = cardValueType === "currency";

  const missingData =
    StatsApiResponseTypes.LINE.includes(type) && data && !data[0]?.data?.length;
  const graphHeaderValue =
    missingData || (!cardValue && cardValue !== 0)
      ? undefined
      : cardValue / (isCurrency ? 100 : 1);

  const fullyRendered = () => setRendered(true);

  useEffect(() => {
    if (missingData) fullyRendered();
  }, [missingData]);

  type === StatsApiResponseTypes.NUMBER && fullyRendered();

  return (
    <Container
      className={twMerge(
        "flex flex-1 flex-col p-8 text-gray-200",
        className,
        gradient &&
          "bg-[linear-gradient(165deg,var(--tw-gradient-stops))] from-primary to-gray-800 to-50%",
      )}
    >
      <div
        className={twMerge(
          "opacity-0 transition-opacity duration-300",
          rendered && "opacity-100",
        )}
      >
        <Row className="w-full flex-1 gap-4" up>
          {title && (
            <GraphHeader
              title={title}
              description={description}
              unit={cardValueUnit}
              noSpacing={numberGraph}
              analyticsData={analyticsData}
              gradient={gradient}
              {...(missingData ? {} : { value: graphHeaderValue })}
            />
          )}
          {queryUrl && (
            <Row up>
              <Link
                href={`/${selectedVendor}${queryUrl}`}
                className="gap-1 text-base font-medium text-white row sm:text-base"
              >
                More details
                <Icon name="chevron-right" className="text-[10px]" />
              </Link>
            </Row>
          )}
        </Row>

        {missingData ? (
          <Row className="h-full w-full text-center">
            There is not enough
            <br />
            data to draw this graph.
          </Row>
        ) : (
          <>
            {[StatsApiResponseTypes.LINE].includes(type) && (
              <Line
                data={data}
                isLegacyGraph={isLegacyGraph}
                singleDate={singleDate}
                isCurrency={isCurrency}
                graphConfig={graphConfig}
                fullyRendered={fullyRendered}
              />
            )}
            {type === StatsApiResponseTypes.RADIAL_BAR && (
              <RadialBar data={data} fullyRendered={fullyRendered} />
            )}
            {type === StatsApiResponseTypes.HEATMAP && (
              <Heatmap data={data} fullyRendered={fullyRendered} />
            )}
            {[
              StatsApiResponseTypes.CATEGORY,
              StatsApiResponseTypes.PIE,
            ].includes(type) && (
              <Pie data={data} fullyRendered={fullyRendered} />
            )}
            {type === StatsApiResponseTypes.BAR && (
              <Bar
                data={data}
                indexBy={indexBy}
                isCurrency={isCurrency}
                startDate={startDate}
                endDate={endDate}
                graphConfig={graphConfig}
                fullyRendered={fullyRendered}
              />
            )}
            {type === StatsApiResponseTypes.HORIZONTAL_BAR && (
              <HorizontalBar
                data={data}
                indexBy={indexBy}
                isCurrency={isCurrency}
                startDate={startDate}
                endDate={endDate}
                graphConfig={graphConfig}
                fullyRendered={fullyRendered}
              />
            )}
            {type === StatsApiResponseTypes.CHOROPLETH && (
              <Choropleth data={data} fullyRendered={fullyRendered} />
            )}
            {type === StatsApiResponseTypes.SCATTER_PLOT && (
              <ScatterPlot data={data} fullyRendered={fullyRendered} />
            )}
          </>
        )}
      </div>
    </Container>
  );
};
