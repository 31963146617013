import dynamic from "next/dynamic";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import Tooltip from "@components/Nivo/Tooltip";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const ResponsiveRadialBar = dynamic(
  () =>
    import("@nivo/radial-bar").then(
      ({ ResponsiveRadialBar }) => ResponsiveRadialBar,
    ),
  { ssr: false },
);

const transform = (data: [string, number][]) =>
  data
    .map(([key, value]) => ({
      id: key,
      data: [
        {
          x: key,
          y: value,
        },
      ],
    }))
    .sort((a, b) => b.data[0].y - a.data[0].y);

const COLORS = [
  twColors.lime,
  twColors.olive,
  twColors.orange,
  twColors.crimson,
  twColors.pink,
  twColors.baby,
] as string[];

export default ({
  data,
  fullyRendered = () => {},
}: {
  data: [string, number][];
  fullyRendered?: Function;
}) => {
  const totalCount = data
    .map((item) => item[1] as number)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <div className="aspect-[2/1] w-full row">
      {Object.keys(data).length ? (
        <ResponsiveRadialBar
          layers={[
            "grid",
            "tracks",
            "bars",
            "labels",
            "legends",
            () => fullyRendered(),
          ]}
          data={transform(data)}
          theme={SesamyNivoTheme}
          margin={{ top: 0, right: 180, bottom: 0, left: 0 }}
          colors={COLORS}
          valueFormat=">-.2f"
          padding={0.5}
          cornerRadius={15}
          innerRadius={0.15}
          tracksColor={twColors.gray[600]}
          enableRadialGrid={false}
          enableCircularGrid={false}
          startAngle={0}
          endAngle={360}
          radialAxisStart={null}
          maxValue={totalCount}
          circularAxisOuter={null}
          tooltip={({ bar: { category, value, color } }) => (
            <Tooltip className="gap-2 row-left">
              <div
                className="h-2 w-2 rounded-full"
                style={{ backgroundColor: color }}
              />
              <span className="text-gray-300">{category}</span> {value}
            </Tooltip>
          )}
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 6,
              itemDirection: "left-to-right",
              itemWidth: 100,
              itemHeight: 20,
              itemTextColor: "#999",
              symbolSize: 10,
              symbolShape: "circle",
            },
          ]}
        />
      ) : (
        "No data to show"
      )}
    </div>
  );
};
