import { useSesamyAuthTokenContext } from "@contexts/sesamy-auth-token-context";

export default function useSesamyAuthFetch() {
  const { accessToken, getNewSesamyToken } = useSesamyAuthTokenContext();

  const fetcher = async (url: string) => {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!res.ok) {
      await getNewSesamyToken();

      // throw an error here so that SWR retries
      throw new Error("Access denied");
    }

    const body = await res.json();

    return body;
  };

  const isReady = !!accessToken;

  return { fetcher, isReady };
}
