import { uniq, fromPairs } from "lodash";
import tailwindConfig from "../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

export const GRAPH_COLORS = [
  twColors.purple[700],
  twColors.purple[600],
  twColors.purple[500],
  twColors.purple[300],
  twColors.pear[100],
  twColors.pear[200],
  twColors.pear[300],
];

type Accessor =
  | `${string}Subscriptions`
  | `${string}Subscription`
  | `${string}Rate`
  | "totalSales"
  | "n_items";

export enum StatsApiResponseTypes {
  LINE = "line",
  BAR = "bar",
  HORIZONTAL_BAR = "horizontal-bar",
  PIE = "pie",
  NUMBER = "number",
  TABLE = "table",
  CATEGORY = "category ",
  HEATMAP = "heatmap",
  RADIAL_BAR = "radial-bar",
  CHOROPLETH = "choropleth",
  SCATTER_PLOT = "scatter-plot",
}

export type StatsResponse =
  | LineBarResponse
  | TableResponse
  | CategoricalResponse
  | NumberResponse
  | HeatmapResponse;

export type StatsApiResponseItem = {
  [key: string]: number | string;
  date: string;
};

export type HeatmapResponse = {
  data: StatsApiResponseItem[];
  description: string;
  title: string;
  type: StatsApiResponseTypes.HEATMAP;
};

export type LineBarResponse = {
  data: StatsApiResponseItem[];
  title: string;
  description: string;
  type: StatsApiResponseTypes.LINE | StatsApiResponseTypes.BAR;
  cardValue: number;
  cardValueUnit: string;
  xData: "date";
  yData: string[];
};
export type CategoricalResponse = {
  data: { [key: string]: number | string }[];
  title: string;
  description: string;
  type: StatsApiResponseTypes.CATEGORY;
  cardValue?: number;
  cardValueUnit?: string;
  categoryValue: string;
  values: string[];
  orient: "v" | "h";
  labelFormatter?: string;
};

export type GeographicalMapResponse = {
  data: { id: string; value: number }[];
  title: string;
  description: string;
  type: StatsApiResponseTypes.CHOROPLETH;
  cardValue?: number;
};

export type TableResponse = {
  data: { [key: string]: number | string }[];
  title: string;
  description: string;
  type: StatsApiResponseTypes.TABLE;
  columns: string[];
};

export type NumberResponse = {
  title: string;
  type: StatsApiResponseTypes.NUMBER;
  value: number;
  unit?: string;
};

type BreakDownTypes = {
  id: string;
  name: string;
  data: Data;
};

export type Data = {
  date?: string;
  timeStamp?: number;
  name?: string;
  value?: number;
  n_items?: number;
  [key: `${string}Subscriptions`]: number;
  [key: `${string}Subscription`]: number;
  [key: `${string}Rate`]: number;
  [key: `${string}Sales`]: number;
  breakDownTypes?: Array<BreakDownTypes>;
};

export function getSubscriptionNames(items?: Data[]) {
  // function should just take one breakdowntypes array
  if (!items) return;
  if (!items[0]) return;
  if (!items[0].breakDownTypes) return;

  return uniq(items[0].breakDownTypes.map((brItem) => brItem.name));
}

function reduceSubscriptionNames(
  accessor: Accessor,
  breakDownTypes: Array<BreakDownTypes>,
  subscriptionNames: string[],
) {
  const result = subscriptionNames.map((subscriptionName) => {
    const breakdownType = breakDownTypes.find(
      (b) => b.name === subscriptionName,
    );
    if (breakdownType) {
      return [subscriptionName, breakdownType.data[accessor]];
    }
    return [subscriptionName, 0];
  });

  return fromPairs(result);
}

export function getFormattedData(
  accessor: Accessor,
  items?: Data[],
  subscriptionNames?: string[],
) {
  if (!items) return;
  if (!subscriptionNames) return;
  if (!subscriptionNames) return;

  const result = items.map((item: Data) => {
    const { breakDownTypes } = item;
    if (!breakDownTypes) return {};
    return {
      date: item.date,
      total: item.cancelledSubscriptions,
      ...reduceSubscriptionNames(accessor, breakDownTypes, subscriptionNames),
    };
  });

  return result;
}
