export const getYYYYMMDD = (date: Date) => {
  return new Intl.DateTimeFormat("sv", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

export const isDateInTheFuture = (date: string) => {
  return new Date(date) > new Date();
};

export const isValidDate = (string: string) => !isNaN(Date.parse(string));
