import { DynamicObject } from "@type-defs/common";
import { mergeObjects } from "@utils/object-manipulation";
import { shortDateFormat } from "@utils/numberFormat";

export const chunkArrayOfObjects = (
  items: DynamicObject[],
  indexBy: string,
  chunks: number = 10,
) => {
  if (items.length === 0 || chunks > items.length) return items;

  const overflow = items.length % chunks;
  const chunkSize = (items.length - overflow) / chunks;

  let overflowCount = 0;
  return new Array(chunks).fill({}).map((_, i) => {
    const currentOverflow = +(overflow - i > 0);

    const chunkedItems = items.slice(
      i * chunkSize + overflowCount,
      i * chunkSize + overflowCount + chunkSize + currentOverflow,
    );

    overflowCount += currentOverflow;

    const { [indexBy]: firstTitle } = chunkedItems[0];
    const { [indexBy]: lastTitle } = chunkedItems[chunkedItems.length - 1];

    const from =
      indexBy === "date" ? shortDateFormat(firstTitle, false) : firstTitle;
    const to =
      indexBy === "date" ? shortDateFormat(lastTitle, true) : lastTitle;

    return {
      ...mergeObjects(...chunkedItems),
      [indexBy]: firstTitle,
      range: `${from} - ${to}`,
    };
  });
};

export const definedArrayValuesToString = (
  array: (string | number | undefined | null)[],
) => {
  return array.filter(Boolean).join(" ");
};
