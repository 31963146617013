import dynamic from "next/dynamic";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import Tooltip from "@components/Nivo/Tooltip";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import { useEffect } from "react";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const ResponsiveCalendar = dynamic(
  () =>
    import("@nivo/calendar").then(
      ({ ResponsiveTimeRange }) => ResponsiveTimeRange,
    ),
  { ssr: false },
);

const getFirstDayOfMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return new Date(year, month, 1);
};

const getLastDayOfMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return new Date(year, month, 0);
};

const oneYearAgoFromDate = (date: Date) => {
  date.setUTCFullYear(date.getFullYear() - 1);
  return date;
};

const getMondayOfWeek = (date: Date) => {
  date.setDate(date.getDate() + ((7 - date.getDay()) % 7));
  return date;
};

export default ({
  data,
  fullyRendered = () => {},
}: {
  data: any;
  fullyRendered?: Function;
}) => {
  const from = getMondayOfWeek(
    getFirstDayOfMonth(oneYearAgoFromDate(new Date())),
  );
  const to = getMondayOfWeek(getLastDayOfMonth(new Date()));

  useEffect(() => {
    fullyRendered(true);
  }, []);

  return (
    <div className="aspect-[1.8/1] max-h-[100px] w-full">
      <ResponsiveCalendar
        data={data}
        theme={{
          ...SesamyNivoTheme,
          labels: {
            text: {
              fontSize: 12,
              fill: twColors.gray[300],
            },
          },
        }}
        emptyColor="#1E1E27"
        colors={
          [...Object.values(twColors.purple).reverse().slice(0, 4)] as string[]
        }
        margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
        dayBorderWidth={0}
        weekdayTicks={[0, 2, 4, 6]}
        firstWeekday="monday"
        from={from}
        to={to}
        dayRadius={2}
        daySpacing={2}
        tooltip={({ day, value }) => (
          <Tooltip className="gap-2 row-left">
            <span className="text-gray-300">{day}</span> {value}
          </Tooltip>
        )}
      />
    </div>
  );
};
