import React, { useRef } from "react";
import useOnClickOutside from "@hooks/use-click-outside";
import { twMerge } from "tailwind-merge";

type InfoProps = {
  onClose: () => void;
  info: string | React.ReactNode;
  position?: "top" | "bottom";
};

export default ({ info, onClose, position = "top" }: InfoProps) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);
  return (
    <div
      ref={ref}
      className={twMerge(
        "absolute left-0 z-50 mb-2 max-w-lg rounded-xl border border-gray-700 bg-black p-5 text-sm text-white",
        position === "bottom" && "top-full",
        position === "top" && "bottom-full ",
      )}
    >
      {info}
    </div>
  );
};
