import { DynamicObject } from "@type-defs/common";

export const mergeObjects = (...objects: DynamicObject[]) => {
  const result: DynamicObject = {};

  objects.forEach((obj) => {
    for (const key in obj) {
      if (result.hasOwnProperty(key)) {
        result[key] += obj[key];
      } else {
        result[key] = obj[key];
      }
    }
  });

  return result;
};

export const updateNestedObject = (
  obj: DynamicObject,
  keyPath: string,
  value: any,
) => {
  const keys = keyPath.split(".");
  const lastKey = keys.pop() as string;

  const deepObj = keys.reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = {};
    }
    return acc[key];
  }, obj);

  deepObj[lastKey] = value;
  return { ...obj };
};
