import { useState } from "react";
import InfoModal from "@components/InfoTooltip/InfoModal";
import { twMerge } from "tailwind-merge";
import Icon from "@components/Ui/Icon";

type TooltipProps = {
  title?: string;
  description?: string;
  position?: "top" | "bottom";
};

export default ({ title, description, position }: TooltipProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.stopPropagation();
    setShowInfo(!showInfo);
  };
  const info = (
    <>
      {title && <div className="mb-0.5 font-semibold">{title}</div>}
      {description && <div>{description}</div>}
    </>
  );
  return (
    <>
      <button
        disabled={showInfo}
        onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
        className={twMerge(
          "ml-2 h-5 w-5 cursor-pointer rounded-sm bg-purple-700/20 text-purple-700 row hover:bg-purple-700/40 hover:text-gray-100",
          showInfo && "bg-purple-700/40 text-gray-100",
        )}
        aria-label="Open tooltip"
        type="button"
      >
        <Icon className="text-[10px]" name="i" />
      </button>
      {showInfo && (
        <InfoModal
          info={info}
          onClose={() => setShowInfo(false)}
          position={position}
        />
      )}
    </>
  );
};
