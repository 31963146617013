import dynamic from "next/dynamic";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { numberFormat } from "@utils/numberFormat";
const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const ResponsivePie = dynamic(
  () => import("@nivo/pie").then(({ ResponsivePie }) => ResponsivePie),
  { ssr: false },
);

// TODO: change so /stats/discounts/getDiscountPerformance?vendorId=kvartal
// returns `id` instead of `name` & `value` instead of `ratio` for each item
const transform = (data: any) =>
  data.map(({ name, ratio, id, value }: any) => ({
    id: name || id,
    name,
    value: ratio || value,
  }));

export default ({
  data,
  fullyRendered = () => {},
}: {
  data: any;
  fullyRendered?: Function;
}) => {
  data = transform(data);
  const colors = Object.values(twColors.gradient) as string[];

  return (
    <div className="aspect-[1.8/1] w-full">
      <ResponsivePie
        layers={[
          "arcs",
          "arcLinkLabels",
          "arcLabels",
          "legends",
          () => fullyRendered(),
        ]}
        colors={colors}
        theme={SesamyNivoTheme}
        enableArcLinkLabels={false}
        data={data}
        margin={{ top: 8, right: 0, bottom: 8, left: 0 }}
        innerRadius={0.5}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabel={({ value }) => numberFormat(value)}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        motionConfig="stiff"
        fill={[
          {
            match: {
              id: "ruby",
            },
            id: "dots",
          },
          {
            match: {
              id: "c",
            },
            id: "dots",
          },
          {
            match: {
              id: "go",
            },
            id: "dots",
          },
          {
            match: {
              id: "python",
            },
            id: "dots",
          },
          {
            match: {
              id: "scala",
            },
            id: "lines",
          },
          {
            match: {
              id: "lisp",
            },
            id: "lines",
          },
          {
            match: {
              id: "elixir",
            },
            id: "lines",
          },
          {
            match: {
              id: "javascript",
            },
            id: "lines",
          },
        ]}
        legends={[
          {
            anchor: "left",
            direction: "column",
            symbolShape: "circle",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 8,
            symbolSize: 10,
            itemDirection: "left-to-right",
          },
        ]}
      />
    </div>
  );
};
