import dynamic from "next/dynamic";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import Tooltip from "@components/Nivo/Tooltip";
import {
  longDateTime,
  numberFormat,
  shortDateFormat,
} from "@utils/numberFormat";
import Row from "@components/Ui/Row";
import { useEffect, useState } from "react";
import { ceilToNearest } from "@utils/calculations";
import { isValidDate } from "@utils/dates";
import Column from "@components/Ui/Column";
import { mergeObjects } from "@utils/object-manipulation";
import { DynamicObject } from "@type-defs/common";
import { StatsApiGraphConfig } from "@type-defs/graph-api";

const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const findBiggestY = (data: DynamicObject[]) => {
  let biggestY = -Infinity;
  for (const obj of data) {
    for (const point of obj.data) {
      if (point.y > biggestY) {
        biggestY = point.y;
      }
    }
  }
  return biggestY;
};

const DEFAULT_TIME_FORMAT = "%Y-%m-%d";

const ResponsiveLine = dynamic(
  () => import("@nivo/line").then(({ ResponsiveLine }) => ResponsiveLine),
  { ssr: false },
);

export default ({
  data,
  isLegacyGraph = false,
  singleDate = false,
  isCurrency = false,
  graphConfig,
  fullyRendered = () => {},
}: {
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  isLegacyGraph?: boolean;
  singleDate?: boolean;
  isCurrency?: boolean;
  graphConfig?: StatsApiGraphConfig;
  fullyRendered?: Function;
}) => {
  const [linearGradientDef, setLinearGradientDef] = useState<any>(null);
  const disabledLegendsState = useState<string[]>([]);
  const [disabledLegends] = disabledLegendsState;

  const colors = Object.values(twColors.gradient) as string[];

  const coloredData = data.map((point, i) => ({
    ...point,
    color: colors[i % colors.length],
  }));
  const filteredData = coloredData.filter(
    ({ id }) => !disabledLegends.includes(id),
  );

  let correctedData = filteredData;

  singleDate = singleDate || data[0].data.length === 1;

  if (singleDate) {
    correctedData = filteredData.map((data) => ({
      ...data,
      data: [
        { ...data.data[0], x: shortDateFormat(data.data[0].x) },
        data.data[0],
      ],
    }));
  }

  const timeFormat = graphConfig?.timeFormat || DEFAULT_TIME_FORMAT;
  const stacked =
    graphConfig?.stacked !== undefined ? !!graphConfig.stacked : false;
  const defaultToPercentage = !!graphConfig?.defaultToPercentage;

  const max = ceilToNearest(
    !stacked
      ? findBiggestY(filteredData)
      : Math.max(
          ...Object.values(
            filteredData.reduce(
              (a, { data }) =>
                mergeObjects(
                  a,
                  data.reduce(
                    (a, { x, y }) => ({ ...a, [x]: (a[x] || 0) + y }),
                    {} as DynamicObject,
                  ),
                ),
              {},
            ) as number[],
          ),
        ),
  );

  useEffect(() => {
    (async () => {
      const nivoCore = await import("@nivo/core");

      setLinearGradientDef(
        nivoCore.linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0 },
        ]),
      );
    })();
  }, []);

  if (!linearGradientDef) return null;

  return (
    <>
      <div className="-ml-[30px] aspect-[2.5/1] max-h-[300px] w-[calc(100%+30px)]">
        {data.length > 0 && data[0].data.length > 0 && (
          <ResponsiveLine
            layers={[
              "grid",
              "markers",
              "axes",
              "areas",
              "crosshair",
              "lines",
              "points",
              "slices",
              "mesh",
              "legends",
              () => fullyRendered(),
            ]}
            data={correctedData}
            theme={SesamyNivoTheme}
            colors={({ color }) => color}
            enablePoints={false}
            enableArea={true}
            defs={[linearGradientDef]}
            fill={[{ match: "*", id: "gradientA" }]}
            sliceTooltip={(datum) => {
              const {
                slice: { points },
              } = datum;

              const sortedPoints = points.sort(
                ({ data: { y: aY } }: any, { data: { y: bY } }: any) => bY - aY,
              );

              const date = sortedPoints[0].data.x as string;
              const xFormatted = sortedPoints[0].data.xFormatted as string;

              return (
                <Tooltip className="invisible animate-appear gap-2 column-left">
                  <Column className="gap-2" left>
                    {!isLegacyGraph && (
                      <span className="font-medium">
                        {isValidDate(date)
                          ? xFormatted.split(":").length == 4 // Checks for format "YYYY-MM-DD:hh:ii:ss"
                            ? longDateTime(date)
                            : shortDateFormat(date)
                          : String(sortedPoints[0].data.x)}
                      </span>
                    )}
                    <Column className="w-full gap-1" left>
                      {!isLegacyGraph &&
                        !graphConfig?.hideTotal &&
                        sortedPoints.length > 1 && (
                          <>
                            <Row
                              className="w-full !justify-between gap-10 font-medium"
                              left
                            >
                              <Row className="gap-2" left>
                                <span className="capitalize">Total</span>
                              </Row>
                              {numberFormat(
                                sortedPoints.reduce(
                                  (a, { data: { y } }: any) => a + y,
                                  0,
                                ) / (isCurrency ? 100 : 1),
                                false,
                                isCurrency ? 0 : 2,
                              )}
                            </Row>
                            <hr className="my-2 h-[1px] w-full border-none bg-gradient-to-r from-gray-400  to-transparent" />
                          </>
                        )}
                      {sortedPoints.map(
                        ({ serieId, serieColor, data: { yFormatted, y } }) => (
                          <Row
                            className="w-full !justify-between gap-10"
                            key={serieId}
                            left
                          >
                            <Row className="gap-2" left>
                              <div
                                className="h-2 w-2 -translate-y-px rounded-full"
                                style={{
                                  backgroundColor: serieColor,
                                }}
                              />
                              <span className="capitalize">{serieId}</span>
                            </Row>
                            <>
                              {defaultToPercentage
                                ? `${yFormatted}%`
                                : numberFormat(
                                    parseFloat(y as string) /
                                      (isCurrency ? 100 : 1),
                                    false,
                                    isCurrency ? 0 : 2,
                                  )}
                            </>
                          </Row>
                        ),
                      )}
                    </Column>
                  </Column>
                </Tooltip>
              );
            }}
            gridYValues={4}
            enableGridX={false}
            enableSlices="x"
            margin={{
              top: defaultToPercentage ? 50 : 10,
              right: defaultToPercentage ? 30 : 40,
              bottom: 25,
              left: 30,
            }}
            xFormat={`time:${timeFormat}`}
            xScale={
              isLegacyGraph || singleDate
                ? { type: "point" }
                : { type: "time", format: timeFormat, useUTC: false }
            }
            yScale={{
              type: "linear",
              ...(defaultToPercentage ? { min: 0 } : { min: 0 }),
              ...(defaultToPercentage ? { max: 100 } : { max }),
              stacked,
              reverse: false,
            }}
            curve={defaultToPercentage ? "linear" : "monotoneX"}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={{
              ...(defaultToPercentage
                ? {}
                : { tickValues: [0, max * 0.75, max * 0.5, max * 0.25, max] }),
              format: (value) => {
                const isNumber = Math.floor(value) === value;

                if (isNumber) {
                  const number = value / (isCurrency ? 100 : 1);
                  return number >= 1000000
                    ? `${number / 1000000}M`
                    : number >= 1000
                      ? `${number / 1000}K`
                      : number;
                }
                return "";
              },
            }}
            axisBottom={
              isLegacyGraph
                ? {}
                : {
                    tickValues: Math.min(filteredData[0].data.length - 1, 6),
                    format: (e) =>
                      isValidDate(e) ? shortDateFormat(e, false) : e,
                  }
            }
            axisLeft={null}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        )}
      </div>
    </>
  );
};
