import dynamic from "next/dynamic";
import SesamyNivoTheme from "@components/Nivo/SesamyNivoTheme";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { shortDateFormat } from "@utils/numberFormat";

const tailwindStyles = resolveConfig(tailwindConfig) as any;
const twColors = tailwindStyles.theme.colors;

const ResponsiveScatterPlot = dynamic(
  () =>
    import("@nivo/scatterplot").then(
      ({ ResponsiveScatterPlot }) => ResponsiveScatterPlot,
    ),
  { ssr: false },
);

const colors = [
  twColors.lime,
  twColors.olive,
  twColors.orange,
  twColors.crimson,
  twColors.pink,
  twColors.baby,
] as string[];

export default ({
  data,
  fullyRendered = () => {},
}: {
  data: any;
  fullyRendered?: Function;
}) => {
  return (
    <div className="aspect-[1.8/1] w-full">
      <ResponsiveScatterPlot
        layers={[
          "grid",
          "axes",
          "nodes",
          "markers",
          "mesh",
          "legends",
          "annotations",
          () => fullyRendered(true),
        ]}
        data={data}
        colors={colors}
        theme={SesamyNivoTheme}
        margin={{ top: 10, right: 30, bottom: 30, left: 30 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          precision: "day",
        }}
        axisBottom={{
          format: "%b %d",
          tickValues: 8,
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
        }}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        gridYValues={4}
        nodeSize={11}
        legends={[]}
        tooltip={({ node }) => {
          return (
            <div className="gap-1 rounded-2xl bg-gray-700 p-3 text-xs row-left">
              {typeof node.formattedX === "string" && (
                <span>{shortDateFormat(node.formattedX)}:</span>
              )}
              <span>
                <strong>{node.formattedY.toLocaleString()}</strong>
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};
