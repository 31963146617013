import { numberFormat } from "@utils/numberFormat";
import { twMerge } from "tailwind-merge";

type Props = {
  value?: number;
  unit?: string;
  gradient?: boolean;
};

export default ({ value, unit, gradient = false }: Props) => {
  if (!value && value !== 0) {
    return null;
  }

  return (
    <div className="font-medium text-white">
      {numberFormat(value, false, unit === "%" ? 2 : 0)}

      {!!unit && (
        <span
          className={twMerge(
            unit !== "%" &&
              "relative top-1 ml-2 align-top text-[length:calc(var(--headline-font-size,theme(spacing.10))/2.5)] leading-[80%] tracking-normal",
            gradient && "text-xl",
          )}
        >
          {unit}
        </span>
      )}
    </div>
  );
};
