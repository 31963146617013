import { DynamicObject } from "@type-defs/common";
import { ReactNode, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = { children: ReactNode; className?: string; style?: DynamicObject };

export default ({ children, className = "", style = {} }: Props) => {
  const [offsetX, setOffsetX] = useState(0);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: MouseEvent) => {
    if (!tooltipRef.current) return;
    const { pageX } = e;
    const { innerWidth } = window;
    const { parentElement } = tooltipRef.current;
    if (!parentElement) return;
    const { width } = parentElement?.getBoundingClientRect();

    const halfWidth = width / 2;
    const tooltipX = pageX + halfWidth;
    const overflowX = innerWidth - (tooltipX + 15); // 15 is the scrollbar width

    if (overflowX < 0) setOffsetX(-Math.abs(overflowX));
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div
      ref={tooltipRef}
      className={twMerge(
        "rounded-md bg-gray-700 p-3 text-sm shadow-md sm:text-sm",
        className,
      )}
      style={{ ...style, transform: `translateX(${offsetX}px)` }}
    >
      {children}
    </div>
  );
};
